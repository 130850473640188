/*!
 *        _             __ _  _   
 *       | |           /_ | || |  
 *    ___| |__   ___ ___| | || |_ 
 *   / __| '_ \ / _ \_  / |__   _|
 *  | (__| | | |  __// /| |  | |  
 *   \___|_| |_|\___/___|_|  |_|  
 *
 *  Crafted by Chez14
 *  
 * Oxam is a part of Informatika UNPAR's Family.
 * This project uses Bootstrap to fuel the front end.
 */
@import "~opensans-npm-webfont/style.css";
$font-family-sans-serif: "Open Sans", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
$headings-margin-bottom: (
  $spacer /1.7
);
$headings-font-weight: 100;
$headings-line-height: 1.2;
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

.h-100vh {
  height: 100vh;
}

.h-50vh {
  height: 50vh;
}

.h-75vh {
  height: 75vh;
}

.modal-90w {
  width: 90vw;
}

.editor {
  display: inline-flex;
  gap: 10px;
  font-family: monospace;
  line-height: 21px;
  border-radius: 2px;
  padding: 20px 10px;
  height: 22em;
  overflow-y: scroll;
}

.line-numbers {
  width: 20px;
  text-align: right;
}

.line-numbers span {
  counter-increment: linenumber;
}

.line-numbers span::before {
  content: counter(linenumber)".";
  display: block;
  color: rgba(53, 61, 61, 0.363);
}

#text-area-npm-peserta {
  height: max-content;
  line-height: 21px;
  overflow-y: hidden;
  padding: 0;
  border: 0;
  min-width: 500px;
  outline: none;
  resize: none;
}